// variables
@import "partials/variables";

// override variables for IDP
@import "partials/idp/variables";

// vendors
@import "partials/idp/vendors";

// global styles
@import "partials/idp/global";
@import "partials/buttons";
@import "partials/idp/form";
@import "partials/idp/header";

@import "partials/idp/security";
