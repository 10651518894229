// paths
$front-bundle-path: '/bundles/front';

// colors
$pichet-colors: (
    'blue':                #29444d,
    'blue-light':          #f7f6f5,
    'green':               #20d0a8,
    'green-light':         #c2e9cc,
    'green-lighter':       #edfcf0,
    'green-lighter-bis':   #effbf2,
    'green-lighter-2':     #dbf2ea,
    'grey':                #8c8c8c,
    'grey-medium':         #cbcbcb,
    'grey-light':          #f7f7f7,
    'grey-lighter':        #fbfbfb,
    'grey-lighter-2':      #c4c4c4,
    'grey-lighter-bis':    #FAFAFA,
    'grey-dark':           #495057,
    'grey-darker':         #aaaaaa,
    'red':                 #e7305b,
    'red-light':           #f1d2d2,
    'black-shadow':        rgba(#000000, 0.35),
    'white':               #ffffff
);

// override bootstrap variables
$body-bg: map-get($pichet-colors, 'white');

$text-color: map-get($pichet-colors, 'blue');

$font-family-base: 'montserrat-regular', 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family-base-bold: 'montserrat-bold', $font-family-base;
$font-size-base: 16px;
$font-size-h1: 30px;

$grid-gutter-width: 20px;

$brand-primary: map-get($pichet-colors, 'green');
$brand-danger: map-get($pichet-colors, 'red');

$input-border: map-get($pichet-colors, 'green');
$input-color-placeholder: map-get($pichet-colors, 'grey');
$input-border-focus: map-get($pichet-colors, 'green');

$state-danger-text:   map-get($pichet-colors, 'red');
$state-danger-bg:     map-get($pichet-colors, 'red-light');
$state-danger-border: map-get($pichet-colors, 'red');

$modal-backdrop-bg: map-get($pichet-colors, 'white');
$modal-backdrop-opacity: .6;

$panel-border-radius: 10px;
$panel-heading-padding: 20px 25px;
$panel-body-padding: 25px;
$panel-default-text: map-get($pichet-colors, 'blue');
$panel-default-border: map-get($pichet-colors, 'grey-medium');
$panel-default-heading-bg: map-get($pichet-colors, 'grey-light');

$btn-border-radius-base: 18px;
$btn-default-bg: map-get($pichet-colors, 'grey-light');
$btn-default-color: map-get($pichet-colors, 'blue');

// override slick variables
$slick-font-path: '/assets/fonts/';
$slick-loader-path: '/assets/images/';

// icomoon font pichet
$icomoon-font-path: '/assets/fonts';

// Form
$form-input-border-radius: 20px;
$form-input-height: 37px;
$input-height-base: $form-input-height;
$input-border-radius: $form-input-border-radius;
