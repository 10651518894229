label {
    font-weight: normal;
}

.form-control {
    font-size: 18px;
    color: map-get($pichet-colors, 'grey');
}

input[type="radio"] {
    margin-top: 5px;
    vertical-align: middle;
}

.has-error {
    @include form-control-validation($text-color, map-get($pichet-colors, 'red'), map-get($pichet-colors, 'red-light'));

    .form-control {
        background-color: map-get($pichet-colors, 'red-light');
    }

    .help-block {
        text-align: right;
        color: map-get($pichet-colors, 'red');
        text-transform: uppercase;
        font-size: 12px;
        font-style: italic;
        margin-top: 0;
    }
}

.checkbox {
    margin-top: 5px;
    margin-bottom: 5px;

    label {
        &::after {
            padding-top: 7px;
        }
    }
}
