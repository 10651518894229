/***** Backgrounds *****/
.bg-white {
    background-color: map-get($pichet-colors, 'white');
}

/***** Container *****/
.container-fluid {
    padding: 0;
}

/***** Titles *****/
.page-title {
    background-color: map-get($pichet-colors, 'white');
    padding: 20px 0;
}

h1 {
    font-size: $font-size-h1;
    font-weight: bold;
    margin: 0;
}

@media (max-width: $screen-xs-max) {
    .page-title h1 {
        padding-left: 20px;
    }
}
