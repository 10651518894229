.header {
    height: 80px;
    margin-bottom: 1px;

    .logo {
        display: inline-block;

        .logo-image {
            height: 30px;
            width: 193px;
            margin: 25px 0 25px 30px;
        }
    }
}
