.btn {
    font-family: $font-family-base-bold;
    text-transform: uppercase;
    border: none;
    padding: 7px 17px;
    font-size: 13px;

    .fa {
        font-size: 18px;
        padding-right: 10px;
    }
}

.btn-triangle {
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
}
