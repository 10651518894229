h1 {
    padding: 40px 0;
}

.alert {
    display: none;
}

.login-page {
    a, a:hover, a:visited {
        color: map-get($pichet-colors, 'blue');
    }

    .security-link {
        font-weight: 600;
        font-size: 12px;
        text-decoration: underline;
    }

    .checkbox {
        display: inline-block;
        line-height: 18px;

        + .checkbox {
            margin-top: 7px;
        }
    }

    .video-block {
        .video-wrapper {
            padding-bottom: 50px;
            padding-top: 25px;

            .description {
                font-size: 13px;

                &:first-child {
                    margin: 0 24px 11px 24px;
                }
            }

            .video-iframe {
                display: inline-block;
                margin-top: 30px;
                width: 100%;
                max-width: 460px;
                height: 259px;
            }
        }
    }
}

.login-page-loader {
    display: none;
    text-align: center;
    font-weight: bold;

    .loader {
        width: 100px;
        display: block;
        margin: 0 auto 10px;
    }
}

.forgot-password-page {
    display: none;

    .form-container {
        margin-top: 25px;
    }

    .control-label {
        text-align: left;
    }

    .form-group {
        border-right: 2px solid map-get($pichet-colors, 'grey-medium');
        height: 40px;
    }

    .back-login {
        display: inline-block;
        text-transform: uppercase;
        padding-top: 20px;
    }

    .send-button {
        display: inline-block;
        padding-top: 10px;
    }
}

.update-password {
    .form-container {
        margin-top: 25px;
    }

    .block-update-password {
        border-right: 2px solid map-get($pichet-colors, 'grey-medium');
    }
}

.verify-email {
    .form-container {
        margin-top: 25px;
    }

    .instruction {
        margin: 11px 0;
        padding: 0;
    }
}

.global {
    .block-container {
        margin-top: 25px;

        .instruction {
            margin: 20px 0;
            padding: 0;
        }

        .back-login {
            display: inline-block;
            text-transform: uppercase;
            padding-top: 10px;
        }
    }
}

@media(max-width: $screen-sm-max) {
    .forgot-password {
        .info {
            margin-top: 20px;
        }
    }
}

@media(max-width: $screen-xs-max) {
    .forgot-password {
        .send-button {
            text-align: center;
        }

        .info {
            margin-top: 20px;
        }
    }
}

.reset-password {
    .auth0-lock-header,
    .auth0-lock-form > p,
    .auth0-lock-badge-bottom {
        display: none;
    }

    .password-security-advice {
        display: block;
    }

    .auth0-lock.auth0-lock {
        font-family: inherit;

        .auth0-lock-submit {
            background-color: map-get($pichet-colors, 'green');
            width: 140px;
            height: 42px;
            border-radius: 2px;
            padding: 8px 30px;
            margin: auto;

            span {
                &::before {
                    content: "Envoyer ";
                    color: map-get($pichet-colors, 'white');
                    text-transform: uppercase;
                    font-weight: bold;
                }

                &::after {
                    color: map-get($pichet-colors, 'white');
                    font-family: FontAwesome;
                    content: $fa-var-chevron-right;
                    font-size: 12px;
                }

                svg {
                    display: none;
                }
            }

            &:hover span {
                -webkit-transform: none;
                   -moz-transform: none;
                    -ms-transform: none;
                     -o-transform: none;
                        transform: none;
            }
        }

        .auth0-loading-container .auth0-loading {
            top: 0;
            left: 50%;
            margin-left: -9px;
            width: 19px;
            height: 19px;
        }

        .auth0-global-message {
            font-size: 16px;

            &.auth0-global-message-error {
                background-color: $alert-danger-bg;
                color: $alert-danger-text;
                padding: 15px;
                margin-bottom: 22px;
                border: 1px solid $alert-danger-border;
                border-radius: 4px;
            }
        }
    }


}
